import {
  Group,
  Image,
  Menu,
  UnstyledButton,
  createStyles,
  rem,
} from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import images from "./images/images";

const data = [
  { label: "RO", image: images.romanian32 },
  { label: "EN", image: images.english32 },
  { label: "HU", image: images.hungarian32 },
];

const useStyles = createStyles((theme, { opened }: { opened: boolean }) => ({
  control: {
    // width: rem(80),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: rem(8),
    // padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    padding: `${rem(4)} ${rem(8)}`,
    borderRadius: theme.radius.md,
    border: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[2]
    }`,
    transition: "background-color 150ms ease",
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[opened ? 5 : 6]
        : opened
        ? theme.colors.gray[0]
        : theme.white,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[5]
          : theme.colors.gray[0],
    },
  },

  label: {
    fontWeight: 500,
    fontSize: theme.fontSizes.sm,
  },

  icon: {
    transition: "transform 150ms ease",
    transform: opened ? "rotate(180deg)" : "rotate(0deg)",
  },
}));
function LanguageSelector() {
  const [opened, setOpened] = useState(false);
  const { classes } = useStyles({ opened });
  const [selected, setSelected] = useState(data[0]);
  const { i18n } = useTranslation();

  useEffect(() => {
    const language = localStorage.getItem("language-client");
    //@ts-ignore
    const browserLanguage = navigator.language || navigator.userLanguage;
    if (language) {
      //@ts-ignore
      const languangeFound = data.find(
        (item) => item.label === language.toUpperCase()
      );
      changeLanguange(languangeFound || data[0]);
    } else if (browserLanguage) {
      const onlyLanguage = browserLanguage.split("-")[0];
      //@ts-ignore
      const languangeFound = data.find(
        (item) => item.label === onlyLanguage.toUpperCase()
      );
      changeLanguange(languangeFound || data[0]);
    } else {
      changeLanguange(data[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function changeLanguange(languangeSelected: {
    label: string;
    image: string;
  }) {
    localStorage.setItem(
      "language-client",
      languangeSelected.label.toLocaleLowerCase()
    );
    setSelected(languangeSelected);
    i18n.changeLanguage(languangeSelected.label.toLocaleLowerCase());
  }

  const items = data.map((item) => (
    <Menu.Item
      icon={<Image src={item.image} width={25} height={25} />}
      onClick={() => changeLanguange(item)}
      key={item.label}
    >
      {item.label}
    </Menu.Item>
  ));

  return (
    <Menu
      onOpen={() => setOpened(true)}
      onClose={() => setOpened(false)}
      radius="md"
      width="target"
      withinPortal
      styles={{
        item: {
          width: "100%",
          padding: `${rem(4)} ${rem(8)}`,
        },
      }}
    >
      <Menu.Target>
        <UnstyledButton className={classes.control}>
          <Group spacing="xs">
            <Image src={selected.image} width={25} height={25} />
            {/* <span className={classes.label}>{selected.label}</span> */}
          </Group>
          <IconChevronDown size="1rem" className={classes.icon} stroke={1.5} />
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown>{items}</Menu.Dropdown>
    </Menu>
  );
}

export default LanguageSelector;
