import { ActionIcon, Button, Tooltip } from "@mantine/core";
import {
  IconBrandWhatsapp,
  IconPhoneCall,
  IconShare,
} from "@tabler/icons-react";
import dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/hu";
import "dayjs/locale/ro";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IOrder } from "../pages/InProgress";
import ReprogramDelivery, { useStyle } from "./ReprogramDelivery";

interface IProps {
  order: IOrder;
  updateDeliveryDate: (
    orderId: string,
    date: Date,
    reason: string,
    time: string
  ) => void;
}
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
function OrderInfo({ order, updateDeliveryDate }: IProps) {
  const { classes } = useStyle();
  const { t, i18n } = useTranslation();
  const [opened, setOpened] = useState<boolean>(false);
  // Use Day.js in your component
  dayjs.locale(i18n.language);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: "10px",
        fontSize: "20px",
        fontWeight: "bold",
        width: "100%",
        padding: "10px",
        boxSizing: "border-box",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
          gap: "10px",
          boxSizing: "border-box",
        }}
      >
        <ActionIcon
          onClick={() => {
            window.location.href = `tel:${order.organizationPhone}`;
          }}
          size="xl"
          variant="filled"
          color="teal.7"
        >
          <IconPhoneCall size={22} />
        </ActionIcon>
        <ActionIcon
          onClick={() => {
            // open whatsapp with message to write to organization about the order saying Salut, am spalat niste produse la voi, as avea niste intrebari despre ele si as vrea sa va trimit niste poze. ID comanda: 123456
            window.open(
              `https://wa.me/4${order.organizationPhone}?text=${t(
                "whatsAppMessage"
              )}${order.generatedTicketId}`
            );
          }}
          size="xl"
          variant="filled"
          color="teal.7"
        >
          <IconBrandWhatsapp size={30} />
        </ActionIcon>
        <Tooltip
          label={t("shareLinkCopied")}
          opened={opened}
          withArrow
          transitionProps={{ duration: 200 }}
        >
          <Button
            color="teal.7"
            variant="filled"
            leftIcon={<IconShare />}
            style={{
              height: "44px",
            }}
            onClick={() => {
              navigator.clipboard.writeText(window.location.href);
              setOpened((o) => !o);
              setTimeout(() => {
                setOpened((o) => !o);
              }, 500);
            }}
          >
            {t("shareButton")}
          </Button>
        </Tooltip>
      </div>
      <div className={classes.key}>
        {t("labelId")}:{" "}
        <span className={classes.values}>{order?.generatedTicketId}</span>
      </div>
      <div className={classes.key}>
        {t("labelName")}: <span className={classes.values}>{order?.name}</span>
      </div>
      <div className={classes.key}>
        {t("labelPhone")}:{" "}
        <span className={classes.values}>{order?.phone}</span>
      </div>
      <div className={classes.key}>
        {t("labelNrOfProducts")}:{" "}
        <span className={classes.values}>{order?.initialProductsNumber}</span>
      </div>
      <div className={classes.key}>
        {t("labelDatePickup")}:{" "}
        <span className={classes.values}>
          {dayjs(order.pickupDate).format("LLLL")}{" "}
        </span>
      </div>
      {order.status === "forDelivery" && (
        <ReprogramDelivery
          order={order}
          updateDeliveryDate={updateDeliveryDate}
          aproxDeliveryDateFormatted={dayjs(order.aproximateDelivery).format(
            "dddd, D MMMM YYYY"
          )}
          aproximateHourDelivery={order.aproximateHourDelivery}
        />
      )}
      {order.status === "delivered" && (
        <div className={classes.key}>
          {t("labelDateDelivery")}:{" "}
          <span className={classes.values}>
            {dayjs(order.deliveryDate).format("LLLL")}
          </span>
        </div>
      )}
    </div>
  );
}
export default OrderInfo;
